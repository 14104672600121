import React, { useRef, useState } from "react";
import Input from "../Utils/Input";
import WaveLoading from "../Utils/WaveLoading";
import Button from "../Utils/Button";
import ForgotPassword from "./ForgotPassword";

export default function LoginPopup(props) {
  const [isError, setIsError] = useState("");
  const [body, updateBody] = useState({
    Email: null,
    Password: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [forgot, setForgot] = useState(false);
  const rfEmail = useRef();
  const rfPassword = useRef();

  const loginUser = () => {
    let d = body;
    d.Email = rfEmail.current.value.toLowerCase().trim();
    d.Password = rfPassword.current.value;
    updateBody(d);
    setIsError("");

    if (!validateEmail(body.Email))
      return setIsError("Please enter a valid email address!");
    if (!validatePassword(body.Password))
      return setIsError("Password must be at least 6 characters!");

    if (validateEmail(body.Email) && validatePassword(body.Password)) {
      setIsLoading(true);
      fetch("/api/uberusers/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          if (data.success) {
            localStorage.setItem("webgfgfgggn", data.token);
            setIsError(data.success);
            setTimeout(() => {
              window.location.href = "/home";
            }, 1000);
          } else {
            setIsError(data.error);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError("Login failed!");
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <>
      <div className="login">
        <div className="container">
          <h1>Login</h1>
          <h6>{isError}</h6>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Input ref={rfEmail} label="Email Address *" type="email" />
            <Input ref={rfPassword} label="Password *" type="password" />
            <p>
              Forgot password?{" "}
              <span
                onClick={() => {
                  setForgot(true);
                }}
              >
                Click here
              </span>
            </p>

            <Button value="Submit" handleClick={loginUser} />
          </form>
          <p>or</p>
          <button
            onClick={() => {
              window.open(`https://wa.me/+254703938266`, "_blank");
            }}
            style={{ backgroundColor: "green", width: "fit-content" }}
          >
            Send on WhatsApp
          </button>
          <p>
            Don't have an account?{" "}
            <span
              onClick={() => {
                props.setIsLogin(false);
              }}
            >
              Register here
            </span>
          </p>
          {isLoading && <WaveLoading />}
        </div>
        {forgot && <ForgotPassword setForgot={setForgot} />}
      </div>
    </>
  );
}
