import { useEffect, useState } from "react";
import ReqeustPopup from "../Requests/RequestPopup";

export default function AccordionBody(props) {
  const [date, setDate] = useState(null);
  const [showing, setShowing] = useState(false);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const d = new Date(props.item.createdAt.replace(" ", "T"));
    setDate(d);
  }, []);

  function formatAMPM(date) {
    var hours = date?.getHours();
    var minutes = date?.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  return (
    <>
      <div
        onClick={() => {
          setShowing(true);
        }}
        className="accordion-body"
      >
        <div className="date">
          <h1>{date?.getDate()}</h1>
          <p>
            {monthNames[date?.getMonth()]} {date?.getFullYear()}{" "}
            {formatAMPM(date)}
          </p>
        </div>
        <div className="bdy">
          <div className="div1auto">
            <div>
              <h5>
                <b>Category: </b>
                {props.item.Category}
              </h5>
            </div>
            <div>
              <p>
                <b> Checks: </b> Identity Check, Police Clearance Check, Driving
                License Check, and PSV Check
              </p>
            </div>
          </div>
        </div>
      </div>
      {showing && (
        <ReqeustPopup
          item={props.item}
          refresh={props.refresh}
          setRefresh={props.setRefresh}
          setShowing={setShowing}
        />
      )}
    </>
  );
}
