import "../Styles/home.scss";
import Navigation from "../components/Utils/Navigation";
import { useState } from "react";
import { useEffect } from "react";
import Settings from "../components/Settings/Settings";
import NavHeader from "../components/Utils/NavHeader";
import MyHome from "../components/MyHome/MyHome";
import NewDocument from "../components/Requests/NewDocument";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const [active, setActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const pathname = window.location.pathname.split("/");

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className="home">
      <div className="main">
        {active && (
          <div className="left_panel">
            <Navigation
              showing={showing}
              setActive={setActive}
              setShowing={setShowing}
            />
          </div>
        )}
        {showing && (
          <div className="left_panel">
            <Navigation
              showing={showing}
              setActive={setActive}
              setShowing={setShowing}
            />
          </div>
        )}
        <div className="right_panel">
          <NavHeader
            showing={showing}
            setActive={setActive}
            setShowing={setShowing}
          />
          <div className="full">
            {pathname[1] === "home" && pathname.length === 2 && (
              <MyHome showing={showing} setShowing={setShowing} />
            )}
            {pathname[2] === "account" && (
              <Settings showing={showing} setShowing={setShowing} />
            )}
            {pathname[2] === "checks" && (
              <NewDocument showing={showing} setShowing={setShowing} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
