import { useEffect, useRef, useState } from "react";
import { AiFillWarning } from "react-icons/ai";
import "../../Styles/requests.scss";
import Input from "../Utils/Input";
import Select from "../Utils/Select";
import WaveLoading from "../Utils/WaveLoading";

export default function NewDocument(props) {
  const [refresh, setRefresh] = useState(false);
  const [documentId, setDocumentID] = useState(null);
  const [showing, setShowing] = useState(true);
  const [UserID, setUserID] = useState(null);
  const jwt = require("jsonwebtoken");
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("webgfgfgggn");
    if (token) {
      try {
        var decoded = jwt.decode(token);
        console.log(decoded);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/";
        } else {
          setUserID(decoded.UserID);
        }
      } catch (error) {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("request");
    const step2 = localStorage.getItem("step2");
    if (token) {
      setDocumentID(token);
    }
  }, [refresh]);

  return (
    <div className="requests">
      <div className="list">
        <h3>Driver Consent</h3>
        <hr />
        <div className="consent">
          <p>
            <AiFillWarning color="blue" className="ic" size={32} />I give my
            consent to Arope Group Limited to conduct the Background Screening
            exercise.
          </p>
          <p>
            <AiFillWarning color="blue" className="ic" size={32} />I understand
            that my participation in the Background Screening exercise is
            voluntary.
          </p>
          <p>
            <AiFillWarning color="blue" className="ic" size={32} />I understand
            that relevant sections of my bio data and Background Checks
            conducted by Arope Group Limited may be looked at by my employer
            from regulatory authorities or from the government security
            officials, where it is relevant to my taking part in this Vetting
            and Screening. I give my permission for these individuals to have
            access to my records.
          </p>
        </div>
        <div className="new">
          <Step1 refresh={refresh} setRefresh={setRefresh} UserID={UserID} />
        </div>
      </div>
    </div>
  );
}

const Step1 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(null);
  const [amt, setAmt] = useState(0);
  const [fodata, setFOData] = useState(null);
  const tt = useRef();
  const ct = useRef();
  const sf = useRef();
  const dob = useRef();
  const phone = useRef();
  const [body, setBody] = useState({
    UserID: props.UserID,
    Passport: null,
    Police: null,
    NationalID: null,
    MPESA: null,
    Category: null,
    DOB: null,
    Phone: null,
  });
  const [minDate, setMinDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  useEffect(() => {
    let d = body;
    d.UserID = props.UserID;
    setBody(d);
  }, [props.UserID]);

  useEffect(() => {
    if (ct.current.value.includes("Rider")) {
      const md = new Date();
      md.setFullYear(md.getFullYear() - 21);
      const minDateString = md.toISOString().split("T")[0];
      setMinDate(minDateString);
    } else {
      const md = new Date();
      md.setFullYear(md.getFullYear() - 24);
      const minDateString = md.toISOString().split("T")[0];
      setMinDate(minDateString);
    }
  }, [amt]);

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.MPESA = tt.current.value;
    d.Category = ct.current.value;
    d.DOB = dob.current.value;
    d.Phone = phone.current.value;
    setBody(d);
    const chck = Object.values(d);
    let valid = true;

    console.log(d);
    chck.map((item) => {
      if (item === "" || item == null) {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");
    if (d.NationalID === undefined) return setError("File is required!");
    if (d.Police === undefined) return setError("File is required!");
    if (d.Passport === undefined) return setError("File is required!");
    setLoading(true);

    const formData = new FormData();

    for (const i in d) {
      formData.append(i, d[i]);
    }

    fetch("/api/uber/create", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);

          setTimeout(() => {
            window.location.href = "/home";
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div>
      <div className="payment">
        <h3>MPESA Payment Details</h3>
        <hr />
        <div className="mpesa">M-PESA</div>
        <p>
          <b>Paybill:</b> 709 777
        </p>
        <p>
          <b>Account No:</b> Your National ID Number
        </p>

        <h5>KSh {amt}</h5>
      </div>
      <h3>New Check</h3>
      <hr />
      <form
        autoComplete="none"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="div2equal">
          <Select
            ref={ct}
            setChanged={(e) => {
              if (e != "" && e.toString().includes("Renewal")) {
                setAmt("500");
              } else setAmt("1,000");
            }}
            label="Select Category *"
            data={[
              "New Driver",
              "Driver Renewal",
              "New Rider",
              "Rider Renewal",
            ]}
          />
          <Input ref={tt} type="text" label="MPESA Payment Code *" />
        </div>

        <div className="div2equal">
          <Input
            ref={dob}
            max={minDate}
            value={minDate}
            type="date"
            label="Date of Birth *"
          />
          <Input ref={phone} type="text" label="Current Phone Number *" />
        </div>

        <div className="div3equal">
          <div className="input">
            <label>National ID *</label>
            <input
              ref={sf}
              type="file"
              label="Upload file *"
              accept=".pdf,.png,.PNG,.jpg,.jpeg,.JPG,.JPEG"
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  let d = body;
                  d.NationalID = e.target.files[0];
                  setBody(d);
                }
              }}
            />
          </div>
          <div className="input">
            <label>Recent Passport Size Photo *</label>
            <input
              ref={sf}
              type="file"
              label="Upload file *"
              accept=".png,.PNG,.jpg,.jpeg,.JPG,.JPEG"
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  let d = body;
                  d.Passport = e.target.files[0];
                  setBody(d);
                }
              }}
            />
          </div>
          <div className="input">
            <label>Police Clearance Certificate/Invoice *</label>
            <input
              ref={sf}
              type="file"
              label="Upload file *"
              accept=".pdf,.png,.PNG,.jpg,.jpeg,.JPG,.JPEG"
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  let d = body;
                  d.Police = e.target.files[0];
                  setBody(d);
                }
              }}
            />
          </div>
        </div>

        <h6>{error}</h6>

        <button
          onClick={() => {
            createDocument();
          }}
        >
          Submit
        </button>
      </form>
      {loading && <WaveLoading />}
    </div>
  );
};
