import React from "react";
import { useState } from "react";
import Header from "../components/Utils/Header";
import "../Styles/landing.scss";
import LoginPopup from "../components/Landing/LoginPopup";
import ForgotPassword from "../components/Landing/ForgotPassword";
import RegisterPopup from "../components/Landing/RegisterPopup";
import Footer from "../components/Utils/footer";
export default function PrivacyPolicy(props) {
  const [isLogin, setIsLogin] = useState(null);
  const [isForgot, setIsForgot] = useState(null);
  const [isRegister, setIsRegister] = useState(null);

  return (
    <div className="terms">
      <Header setIsLogin={setIsLogin} setIsRegister={setIsRegister} />
      <div className="pcontent">
        <h1>Privacy Policy</h1>
        <p>
          Welcome to Arope Group Ltd ("Arope," "we," "us," or "our"). This
          Privacy Policy explains how we collect, use, and disclose your
          personal information when you access or use our website (the "Site")
          and our services (collectively, the "Services"). Please read this
          Privacy Policy carefully before using our Site or Services.
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          When you use our Site or Services, we may collect certain personal
          information, including but not limited to:
        </p>
        <ul>
          <li>
            Personal information provided by you, such as your name, contact
            details, and other information you choose to provide.
          </li>
          <li>
            Information collected automatically, such as your IP address, device
            information, and usage data.
          </li>
          <li>
            Information obtained from third-party sources, such as background
            screening reports and publicly available information.
          </li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>
          We may use the collected information for various purposes, including
          but not limited to:
        </p>
        <ul>
          <li>Providing and improving our Services</li>
          <li>Conducting background screenings and verifications</li>
          <li>Complying with legal obligations</li>
          <li>Communicating with you and responding to your inquiries</li>
          <li>Protecting our rights and the rights of others</li>
        </ul>

        <h2>3. Information Sharing and Disclosure</h2>
        <p>
          We may share your personal information with third parties in certain
          circumstances, including but not limited to:
        </p>
        <ul>
          <li>
            With our service providers and business partners who assist us in
            delivering our Services
          </li>
          <li>
            To comply with legal obligations or respond to lawful requests
          </li>
          <li>
            In connection with a merger, acquisition, or sale of our assets
          </li>
          <li>With your consent or at your direction</li>
        </ul>

        <h2>4. Security</h2>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, please note that no
          method of transmission over the internet or electronic storage is 100%
          secure.
        </p>

        <h2>5. Your Choices and Rights</h2>
        <p>
          You have certain rights regarding your personal information, including
          the right to access, correct, and delete your information. You may
          also have the right to object to or restrict certain processing
          activities. Please contact us using the information provided below to
          exercise your rights.
        </p>

        <h2>6. Updates to this Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any material changes by posting the updated Privacy Policy on
          our Site. Please review this Privacy Policy periodically for any
          changes.
        </p>

        <h2>7. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          privacy practices, please contact us at:
        </p>
        <p>
          Arope Group Limited <span>|</span> Screening & Background Checks
          <span>|</span> Vedic House, 5th Floor, North Wing,
        </p>
        <p>
          P. O. Box 20252 – 00100, GPO <span>|</span> Email:
          <a href="mailto:victoria@aropegroup.com"> victoria@aropegroup.com</a>
        </p>
        <p>
          Phone: <a href="tel:+254725214688">+254 725 214 688</a>,{" "}
          <a href="tel:+254203882062">+254 203 882 062</a> <span>|</span>{" "}
          Website:
          <a href="https://aropegroup.com/">https://aropegroup.com/</a>
        </p>
      </div>
      <Footer />
      {isLogin && (
        <LoginPopup
          setIsForgot={setIsForgot}
          setIsLogin={setIsLogin}
          setIsRegister={setIsRegister}
        />
      )}
      {isForgot && (
        <ForgotPassword
          setIsForgot={setIsForgot}
          setIsLogin={setIsLogin}
          setIsRegister={setIsRegister}
        />
      )}
      {isRegister && (
        <RegisterPopup
          setIsForgot={setIsForgot}
          setIsLogin={setIsLogin}
          setIsRegister={setIsRegister}
        />
      )}
    </div>
  );
}
