import { useEffect, useRef, useState } from "react";
import WaveLoading from "../Utils/WaveLoading";
import { BsInfoSquare } from "react-icons/bs";
import { GrInProgress } from "react-icons/gr";
import { BsFillPersonCheckFill } from "react-icons/bs";
import SubjectsPopup from "./SubjectsPopup";
import Input from "../Utils/Input";

export default function ReqeustPopup(props) {
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState("");
  const [data, setData] = useState(null);
  const [fo, setFO] = useState(null);
  const [fodata, setFOData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [jdets, setJDets] = useState(null);
  const [clicked, setClicked] = useState(null);
  const [udets, setUDets] = useState(null);
  const [tt, setTT] = useState(null);
  const [ct, setCT] = useState(null);
  const [rdata, rsetData] = useState(null);
  const [body, setBody] = useState({
    RequestID: props.item.DocumentID,
    UserID: null,
    Assigned: true,
  });

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/subjects/checks/byjobid/${props.item.DocumentID}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        if (data.length > 0) {
          let total = 0;
          let count = 0;
          data.map((item) => {
            const it = JSON.parse(item.Checks);
            total = total + it.length;
            it.map((i) => {
              if (!i.Status) {
                count += 1;
              }
            });
          });

          setTT(total);
          setCT(count);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    setIsLoading(true);

    fetch(`/api/uber/${props.item.DocumentID}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        setJDets(data);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [props.refresh, refresh]);

  useEffect(() => {
    if (jdets) {
      setIsLoading(true);
      fetch(`/api/uberusers/${jdets?.UserID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          console.log(data);
          setIsLoading(false);
          setUDets(data);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [jdets]);

  useEffect(() => {
    setData(null);
    setIsLoading(true);
    fetch(`/api/tasks/requestid/${props.item.DocumentID}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        if (data.length > 0) {
          setData(data[0]);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  function getDocument(id) {
    setIsLoading(true);
    fetch(`/api/uploads/${id}`)
      .then((res) => {
        if (res.ok) {
          return res.blob();
        } else throw Error("");
      })
      .then((blob) => {
        setIsLoading(false);
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      })
      .catch((e) => {
        setIsLoading(false);
        alert("File not found!");
      });
  }

  useEffect(() => {
    if (jdets) {
      fetch(`/api/uberreport/getuberid/${jdets.DocumentID}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          if (data.length > 0) {
            rsetData(data[0]);
          }
        })
        .catch((e) => {});
    }
  }, [jdets, refresh]);

  return (
    <div data-aos="fade-left" className="popup">
      <div className="pcontainer">
        <div className="tbar">
          <h3>{props.item.Name}</h3>

          <i
            onClick={() => {
              props.setShowing(null);
              props.setRefresh(!props.refresh);
            }}
            className="fa fa-times"
          ></i>
        </div>

        <div className="wcontent">
          <div className="div3equal">
            <div>
              <h5>
                <BsInfoSquare /> Basic Info
              </h5>
              <div className="details">
                <h3>Job Details</h3>
                <div className="u_list">
                  <p>
                    <b>Uber driver's check for : </b> {props.item.Name}
                  </p>
                  <p>
                    <b>Identity Check:</b>{" "}
                    {props.item.IdentityCheck ? "Complete" : "Pending"}
                  </p>
                  <p>
                    <b>Driver Check:</b>{" "}
                    {props.item.DriversCheck ? "Complete" : "Pending"}
                  </p>
                </div>

                <h3>My Details</h3>
                <div className="u_list">
                  <p>
                    <b>Name: </b> {udets && udets?.Name}
                  </p>
                  <p>
                    <b>Phone: </b> {udets && udets?.Phone}
                  </p>
                  <p>
                    <b>Email: </b> {udets && udets?.Email}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h5>
                <GrInProgress /> Progress
              </h5>
              <div className="div prg">
                <Item txt="Received" status={true} />
                <Item
                  txt="Assigned to Staff"
                  status={data ? data.Received : null}
                />
                <Item
                  txt="Actual Checks"
                  status={rdata?.Date != null ? true : false}
                />

                <Item
                  txt="Report Submission"
                  status={rdata?.Report != null ? true : null}
                />
              </div>
            </div>
            {/* <div>
              <h5>
                <BsFillPersonCheckFill /> Report Status
              </h5>
              <div className="u_list">
                {rdata && rdata.Report != null ? (
                  <>
                    <p>
                      Uber Certificate is ready. Click the button below to
                      download the report.
                    </p>
                    <button
                      onClick={() => {
                        window.open(
                          `https://api.aropegroup.com/api/uberreport/getfile/${rdata.ID}`,
                          "_blank"
                        );
                      }}
                    >
                      View/Download Report
                    </button>
                  </>
                ) : (
                  <p>Uber Certificate is not ready!</p>
                )}
              </div>
            </div> */}
          </div>

          {isLoading && <WaveLoading />}
        </div>
      </div>
      {jdets && clicked && (
        <SubjectsPopup
          JobID={props.item.DocumentID}
          Title={props.item.Title}
          setShowing={setJDets}
          setClicked={setClicked}
          refresh={refresh}
          setRefresh={setRefresh}
          task={props.task}
        />
      )}
    </div>
  );
}

const Item = (props) => {
  const [color, setColor] = useState("red");

  useEffect(() => {
    switch (props.status) {
      case true:
        setColor("green");
        break;
      case false:
        setColor("orange");
        break;
      case null:
        setColor("red");
        break;
      default:
        setColor("red");
        break;
    }
  }, [props.status]);

  return (
    <div className="item">
      <div style={{ backgroundColor: color }}></div>
      <p>
        {props.txt} <b>{props.tally ? props.tally : ""}</b>
      </p>
    </div>
  );
};

const SelectedUser = (props) => {
  return (
    <>
      {props.data && (
        <div className="selected_staff">
          <div
            onClick={() => {
              props.clearEvent();
            }}
            className="fa fa-times"
          ></div>
          <p>{props.data.Name}</p>
          <p>{props.data.Phone}</p>
        </div>
      )}
    </>
  );
};
