import React, { useState, useEffect } from "react";
import "../Styles/landing.scss";
import cash from "../assets/images/cash.png";
import nid from "../assets/images/nID.png";
import passport from "../assets/images/passport.png";
import police from "../assets/images/police.png";
import LoginPopup from "../components/Landing/LoginPopup";
import RegisterPopup from "../components/Landing/RegisterPopup";
import Header from "./../components/Utils/Header";

export default function Landing() {
  const [isLogin, setIsLogin] = useState(true);
  const [isRegister, setIsRegister] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (activeIndex == 2) {
        setActiveIndex(0);
      } else setActiveIndex(activeIndex + 1);
    }, 10000);
  }, [activeIndex]);

  return (
    <div className="landing">
      <Header setIsLogin={setIsLogin} setIsRegister={setIsRegister} />
      <div className="div2equal">
        <div className="left">
          <div
            className={`card`}
            style={{
              left: activeIndex == 0 ? "0" : "-100%",
              opacity: activeIndex == 0 ? "1" : "0",
            }}
          >
            <h1>Uber Driver</h1>
            <br />
            <div className="requirements">
              <h2>Requirements</h2>
              <div className="litem">
                <img src={nid} alt="" />
                <p>National ID Card</p>
              </div>
              <div className="litem">
                <img src={nid} alt="" />
                <p>Driving License (Valid)</p>
              </div>
              <div className="litem">
                <img src={passport} alt="" />
                <p>PSV Badge (Licensed)</p>
              </div>
              <div className="litem">
                <img src={police} alt="" />
                <p>Police Clearance Certificate/Good Conduct</p>
              </div>
            </div>
          </div>
          <div
            className={`card`}
            style={{
              left: activeIndex == 1 ? "0" : "-100%",
              opacity: activeIndex == 1 ? "1" : "0",
            }}
          >
            <h1>Uber Rider/Boda</h1>
            <br />
            <br />
            <div className="requirements">
              <h2>Requirements</h2>
              <div className="litem">
                <img src={nid} alt="" />
                <p>National ID Card</p>
              </div>
              <div className="litem">
                <img src={nid} alt="" />
                <p>Driving License (Valid)</p>
              </div>
              <div className="litem">
                <img src={police} alt="" />
                <p>Police Clearance Certificate/Good Conduct</p>
              </div>
            </div>
          </div>
          <div
            className={`card`}
            style={{
              left: activeIndex == 2 ? "0" : "-100%",
              opacity: activeIndex == 2 ? "1" : "0",
            }}
          >
            <h1>Uber Logistics</h1>
            <br />
            <br />
            <div className="requirements">
              <h2>Requirements</h2>
              <div className="litem">
                <img src={nid} alt="" />
                <p>National ID Card</p>
              </div>
              <div className="litem">
                <img src={police} alt="" />
                <p>Police Clearance Certificate/Good Conduct</p>
              </div>
            </div>
          </div>
          <div className="bar">
            <p
              onClick={() => {
                setActiveIndex(0);
              }}
              className={activeIndex == 0 ? "active" : ""}
            >
              Driver
            </p>
            <p
              onClick={() => {
                setActiveIndex(1);
              }}
              className={activeIndex == 1 ? "active" : ""}
            >
              Rider/Boda
            </p>
            <p
              onClick={() => {
                setActiveIndex(2);
              }}
              className={activeIndex == 2 ? "active" : ""}
            >
              Logistics
            </p>
          </div>
        </div>
        <div className="right">
          {isLogin ? (
            <LoginPopup setIsLogin={setIsLogin} />
          ) : (
            <RegisterPopup setIsLogin={setIsLogin} />
          )}
        </div>
      </div>
      <div className="bottom">
        <div className="div2equal">
          <div className="litem">
            <img src={cash} alt="" />
            <div>
              <p>New driver</p>
              <h3>KSh 1,000</h3>
            </div>
          </div>
          <div className="litem">
            <img src={cash} alt="" />
            <div>
              <p>Re-screening</p>
              <h3>KSh 500</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
