import React, { useEffect, useRef, useState } from "react";

export default function NavHeader(props) {
  var jwt = require("jsonwebtoken");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [clicked, setClicked] = useState(false);

  const toggleMenu = () => {
    setClicked(!clicked);
  };

  useEffect(() => {
    const token = localStorage.getItem("webgfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        setCurrentUser(decoded);
        if (Date.now() >= decoded.exp * 1000) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [isAuthenticated]);

  return (
    <div>
      <div className="navheader">
        <i
          className="fa fa-bars"
          onClick={() => {
            props.setActive(true);
          }}
        ></i>
        <div className="right">
          <p>{currentUser.Name}</p>
          <i className="fa fa-user"></i>
        </div>
      </div>
    </div>
  );
}
